import classNames from "classnames";
import { connect } from 'react-redux';
import { store } from "index";
import { setLocale, clearOverrideIntl, setOverrideIntl } from "Redux/I18nActions";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Flag from "@material-ui/icons/Flag";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const us_flag = require("assets/img/flags/US.png");
const gb_flag = require("assets/img/flags/GB.png");
const be_flag = require("assets/img/flags/BE.png");
const nl_flag = require("assets/img/flags/NL.png");
const fr_flag = require("assets/img/flags/FR.png");
const de_flag = require("assets/img/flags/DE.png");

const useStyles = makeStyles(styles);

function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [redirectToLogout, setRedirectToLogout] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleLogoutClicked = () => {
    setOpenProfile(null);
    setRedirectToLogout(true);
  };
  // eslint-disable-next-line no-unused-vars
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleClickLanguage = event => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };

  const setLanguage = (locale) => {
    store.dispatch(clearOverrideIntl());
    fetch(process.env.REACT_APP_OVERRIDE_INTL_BASEHREF+(props.authTablet?.company_id ?? "")+"-"+locale+".json")
      .then((res) => res.json())
      .then((overrideMessages) => {
        console.log(overrideMessages);
        store.dispatch(setOverrideIntl(overrideMessages));
        store.dispatch(setLocale(locale));
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setLocale(locale));
        window.location.reload();
      }
    );
  }

  const handleCloseLanguage = () => {
    setOpenLanguage(null);
  };

  const classes = useStyles();
  const { rtlActive } = props;
  // eslint-disable-next-line no-unused-vars
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  if (redirectToLogout === true) {
    return <Redirect push to='/auth/logout-page' />
  }
  return (
    <div className={wrapper}>
      {/* <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: classes.top + " " + classes.search
        }}
        inputProps={{
          placeholder: rtlActive ? "بحث" : "Search",
          inputProps: {
            "aria-label": rtlActive ? "بحث" : "Search",
            className: classes.searchInput
          }
        }}
      /> */}
      {/* <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        className={searchButton}
      >
        <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
      </Button> */}
      {/* <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : ""
        }}
      >
        <Dashboard
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Dashboard"}
          </span>
        </Hidden>
      </Button> */}
      {/* <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Notifications
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <span className={classes.notifications}>5</span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Notification"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "إجلاء أوزار الأسيوي حين بل, كما"
                        : "Mike John responded to your email"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "شعار إعلان الأرضية قد ذلك"
                        : "You have 5 new tasks"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "ثمّة الخاصّة و على. مع جيما"
                        : "You're now friend with Andrew"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "قد علاقة" : "Another Notification"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "قد فاتّبع" : "Another One"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div> */}

      {/* <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الإعدادات" : "Settings"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogoutClicked}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الخروج" : "Log out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div> */}

      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickLanguage}
          className={classes.buttonLink}
        >
          <Flag
            className={
              classes.headerLinksSvg +
              " " +
              classes.links
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickLanguage} className={classes.linkText}>
              Language
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openLanguage)}
          anchorEl={openLanguage}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openLanguage,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="language-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseLanguage}>
                  <MenuList role="menu">
                  <MenuItem
											onClick={() => {setLanguage("en");}}
											className={dropdownItem}
										>
											<img src={gb_flag} alt="gb_flag" /><img src={us_flag} alt="us_flag" /> English
										</MenuItem>
										{/* <MenuItem
											onClick={() => {setLanguage("en");}}
											className={dropdownItem}
										>
											<img src={us_flag} alt="us_flag" /> English (Simplified)
										</MenuItem> */}
										<MenuItem
											onClick={() => {setLanguage("nl");}}
											className={dropdownItem}
										>
											<img src={be_flag} alt="be_flag" /><img src={nl_flag} alt="nl_flag" /> Nederlands
										</MenuItem>
										{/* <MenuItem
											onClick={() => {setLanguage("nl");}}
											className={dropdownItem}
										>
											<img src={nl_flag} alt="nl_flag" /> Nederlands (Vereenvoudigd)
										</MenuItem> */}
										<MenuItem
											onClick={() => {setLanguage("fr");}}
											className={dropdownItem}
										>
											<img src={be_flag} alt="be_flag" /><img src={fr_flag} alt="fr_flag" /> Français
										</MenuItem>
										{/* <MenuItem
											onClick={() => {setLanguage("fr");}}
											className={dropdownItem}
										>
											<img src={fr_flag} alt="fr_flag" /> Français (Simplifié)
										</MenuItem> */}
                    <MenuItem
											onClick={() => {setLanguage("de");}}
											className={dropdownItem}
										>
											<img src={be_flag} alt="be_flag" /><img src={de_flag} alt="de_flag" /> Deutsch
										</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

const mapStateToProps = function(state) {
	return {
		authTablet: state.auth.authTablet ?? null,
	}
}

export default connect(mapStateToProps)(HeaderLinks);

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
