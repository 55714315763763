import queryString from 'query-string';
import React from "react";
import { Redirect } from "react-router-dom";

class StoragePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          redirectToLogin: false,
        };
    }
    
    componentDidMount() {
      const that = this;

      let params = queryString.parse(this.props.location.search);

      localStorage.setItem("udid", params.udid ?? "");
      if (params.pin) localStorage.setItem("pin", params.pin ?? "");
      if (params.passphrase) localStorage.setItem("passphrase", params.passphrase ?? "");

      that.setState({
        redirectToLogin: true,
      });
    }

    render() {
        if (this.state.redirectToLogin === true) {
            return <Redirect to='/auth/login-page' />
        }

        return <></>;
    }
}

export default StoragePage;