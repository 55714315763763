import * as actionType from './I18nActionType';

export function setLocale(locale){
    return {
        type: actionType.SET_LOCALE,
        payload: locale,
    }
};

export function setOverrideIntl(overrideIntl){
	return {
			type: actionType.SET_OVERRIDE_INTL,
			payload: overrideIntl,
	}
};

export function clearOverrideIntl(){
	return {
			type: actionType.CLEAR_OVERRIDE_INTL,
	}
};